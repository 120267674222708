import MODAL_TOGGLE from './modalTypes';

const initialState = {
  isShown: false,
  modalName: '',
  modalProps: {},
};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case MODAL_TOGGLE: {
      return { ...state, isShown: !state.isShown, ...action.payload };
    }
    default:
      return state;
  }
};
