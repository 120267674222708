import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  suspenseContainer: {
    height: '100vh',
    width: '100vw',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  suspenseCard: {
    width: '50rem',
    maxWidth: '90vw',
    height: '25rem',
    backgroundColor: '#d3d3d3',
    overflow: 'hidden',
    borderRadius: 6,
    position: 'relative',
  },
  gradient: {
    zIndex: 9000,
    display: 'block',
    width: '150px',
    height: '100vh',
    background: 'linear-gradient(90deg, rgba(211,211,211, 0),rgba(211,211,211, .8),rgba(211,211,211, 0))',
    backgroundSize: '100%',
    backgroundPosition: '-150%',
    backgroundRepeat: 'no-repeat',
    position: 'absolute',
    left: '-20%',
    top: '-50px',
    rotate: '20deg',
    animation: '$loading 2.5s linear infinite',
    [theme.breakpoints.down('xs')]: {
      left: '-120%',
      animation: '$loading 2s linear infinite',
    },
  },
  '@keyframes loading': {
    to: {
      left: '150%',
    },
  },
  headerBar: {
    borderBottom: 'solid white 1px',
    display: 'inline-flex',
    position: 'relative',
    width: '100%',
  },
  logo: {
    height: 50,
    width: 50,
    margin: 20,
    borderRadius: '50%',
    backgroundColor: 'white',
  },
  number: {
    height: 30,
    width: 200,
    position: 'absolute',
    right: 20,
    top: 28,
    backgroundColor: 'white',
  },
  articleContainer: {
    margin: 20,
  },
  articleOne: {
    height: 30,
    width: '75%',
    backgroundColor: 'white',
    marginBottom: 20,
  },
  articleTwo: {
    height: 30,
    width: '50%',
    backgroundColor: 'white',
    marginBottom: 20,
  },
  articleThree: {
    height: 30,
    width: '65%',
    backgroundColor: 'white',
    marginBottom: 20,
  },
  articleFour: {
    height: 30,
    width: '30%',
    backgroundColor: 'white',
    marginBottom: 20,
  },
  articleFive: {
    height: 30,
    width: '80%',
    backgroundColor: 'white',
    marginBottom: 20,
  },
}));

// eslint-disable-next-line react/function-component-definition
export default function () {
  const classes = useStyles();

  return (
    <div className={classes.suspenseContainer}>
      <div id="main-card-container" className={classes.suspenseCard}>
        <div className={classes.gradient}> </div>
        <div className={classes.headerBar}>
          <div className={classes.logo}> </div>
          <div className={classes.number}> </div>
        </div>
        <div className={classes.articleContainer}>
          <div className={classes.articleOne}> </div>
          <div className={classes.articleTwo}> </div>
          <div className={classes.articleThree}> </div>
          <div className={classes.articleFour}> </div>
          <div className={classes.articleFive}> </div>
        </div>
      </div>
    </div>
  );
}
