import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ErrorBoundary from './ErrorBoundary';
import LPRedirector from './LPRedirector';

// eslint-disable-next-line react/jsx-filename-extension
ReactDOM.render(<ErrorBoundary><LPRedirector /></ErrorBoundary>, document.getElementById('root'));
