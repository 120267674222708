let baseURL = () => 'https://api.uselenox.com';
const DEV_SERVER = process.env.REACT_APP_LENOX_DEV_SERVER || '';

if (DEV_SERVER) {
  baseURL = () => DEV_SERVER;
}

export const getDataUrlAuto = () => ({
  auto_partial_new: `${baseURL()}/api/v2/external/auto_partial_new.php`,
  auto_dynamic: `${baseURL()}/api/v2/external/auto_dynamic.php`,
  leadco_submit: `${baseURL()}/api/v2/external/lc.php`,
  leadcospecial: `${baseURL()}/api/v2/external/lcspecial.php`,
  auto_partial: `${baseURL()}/api/v2/external/auto_partial.php`,
  lb_partial: `${baseURL()}/api/v2/external/lb_partial.php`,
  auto_new: `${baseURL()}/api/v2/external/auto_new.php`,
  autoPrefill: `${baseURL()}/api/v3/autoPrefill.php`,
  autoPartial: `${baseURL()}/api/v2/external/partial.php`,
});
