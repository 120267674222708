import React from 'react';
import PropTypes from 'prop-types';
import { sendError } from './Requests/AppRequests';
import DomainCheckHelper from './Helpers/DomainCheckHelper';

const logError = (error) => {
  const siteID = DomainCheckHelper.getSiteID();
  sendError(error, siteID);
};

class ErrorBoundary extends React.Component {
  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  componentDidCatch(error, errorInfo) {
    const PORT = process.env.REACT_APP_LENOX_DEV_SERVER || '';
    const errorFullDescription = `${error.message || error} \n ${error.stack}`;
    if (PORT) {
      logError(errorFullDescription);
    }

    localStorage.clear();
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
};

export default ErrorBoundary;
