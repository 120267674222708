import axios from 'axios';
import { getDataUrlAuto } from '../AutoSavings/Endpoints/Endpoints';
import { getDataUrl } from '../Common/Endpoints/Endpoints';

const setParams = (params, action) => {
  if (!params.action) params.action = action;
  return params;
};

export const NetworkRequest = async (endpoint = '', params = {}, action = '', vertical = '') => {
  try {
    let dataUrl;
    switch (vertical) {
      case 'auto':
        dataUrl = getDataUrlAuto();
        break;
      default:
        dataUrl = getDataUrl();
        break;
    }
    return await axios.post(dataUrl[endpoint], { params: setParams(params, action) });
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const NetworkRequestNoParams = async (endpoint = '', params = '', vertical = '') => {
  try {
    let dataUrl;
    switch (vertical) {
      case 'auto':
        dataUrl = getDataUrlAuto();
        break;
      default:
        dataUrl = getDataUrl();
        break;
    }
    return await axios.post(`${dataUrl[endpoint]}?${params}`);
  } catch (e) {
    console.error(e);
    return {};
  }
};

export const NetWorkGetRequest = async (endpoint = '', params = {}, action = '', vertical = '') => {
  try {
    let dataUrl;
    switch (vertical) {
      case 'auto':
        dataUrl = getDataUrlAuto();
        break;
      default:
        dataUrl = getDataUrl();
        break;
    }
    return await axios.get(dataUrl[endpoint], { params: setParams(params, action) });
  } catch (e) {
    console.error(e);
    return {};
  }
};
