import React from 'react';

export const getFallbackSource = (src, fallback) => (
  document.querySelector('html').classList.contains('webp')
    ? src
    : fallback
);

const ImgWithFallback = ({
  alt,
  className,
  src,
  fallback,
  width,
  height,
  lazyLoad = 'lazy',
  ...delegated
}) => (
  <img {...delegated} loading={lazyLoad} className={className} src={getFallbackSource(src, fallback)} alt={alt} width={width || ''} height={height || ''} />
);

export default ImgWithFallback;
