import {
  DELETE_CERTAIN_PET_DATA,
  UPDATE_CERTAIN_NAME,
  UPDATE_INITIAL_PET_DATA,
  UPDATE_PET_SELECTED_BTN,
  UPDATE_STORE,
} from './petType.2000';

const initialState = {
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_INITIAL_PET_DATA: {
      const data = { ...action.payload };
      const updatedData = {};
      Object.keys(data).forEach((field) => {
        if (!state.hasOwnProperty.call(field)) {
          updatedData[field] = data[field];
        }
      });
      if (!state.hasOwnProperty.call('petsIdList')) {
        const baseNumber = data.petNumber.find((i) => i.isActive);
        updatedData.petsIdList = data.petNumber.filter(
          (number) => number.value <= baseNumber.value,
        ).map((id) => id.value);
      }
      return { ...state, ...updatedData };
    }
    case UPDATE_STORE: {
      return { ...state, ...action.payload };
    }
    case UPDATE_CERTAIN_NAME: {
      const { id, ...rest } = action.payload;
      return { ...state, petName: { ...state.petName, [id]: rest.petName } };
    }
    case UPDATE_PET_SELECTED_BTN: {
      const { id, item, storeKey } = action.payload;
      return { ...state, [storeKey]: { ...state[storeKey], [id]: item } };
    }
    case DELETE_CERTAIN_PET_DATA: {
      const { id, storeKey } = action.payload;
      const updatedStore = { ...state[storeKey] };
      delete updatedStore[id];
      return { ...state, [storeKey]: { ...updatedStore } };
    }
    default:
      return state;
  }
};
