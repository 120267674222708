export const initialPetState = {
  activePetId: '',
  lastPage: false,
  variant: 1,
  brand: '',
  activeStepperStep: 0,
  petName: '',
  zipCode: '',
  selectedPetNumber: {},
  selectedPetBreed: {},
  petsData: [],
  formTransitionClass: '',
  firstName: '',
  lastName: '',
  city: '',
  address: '',
  phone: '',
  Email: '',
  state: '',
  stateAbbreviation: '',

  pages: [],
  breedsList: [],
  headerLogo: '',
  headerTitle: '',
  headerBtnList: {},
  footerInfo: '',
  petsTypesList: [],
  agesList: [],
  petGender: [],
  topBreedOptions: [],
  petSpayed: [],
  petVetConsultation: [],
  petsNumberOptions: [],

  autoCompleteValue: '',
  autoCompleteSuggestions: [],
};
