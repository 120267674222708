import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import { merge } from 'lodash';
import petReducer from './Modules/PetModule/petReducer';
import petReducer2000 from './Modules/PetForms/2000/petReducer.2000';
import petReducer3000 from './Modules/PetForms/3000/petReducer.3000';
import loaderReducer from './Modules/Loader/reducer.loader';
import { initialPetState } from './Modules/PetModule/Constants';
import modalReducer from './Modules/Modals/modalReducer';
import mainReducer from './Modules/Main/main.reducer';
import validationReducer from './Modules/Validation/reducer.validation';
import autoReducer from './Modules/Auto/Auto.reducer';
import partialReducer from './Modules/Partial/partial.reducer';

const screenWidth = window.innerWidth;
const mobileSize = 959;
const rootReducer = combineReducers({
  pet: petReducer,
  modal: modalReducer,
  loader: loaderReducer,
  main: mainReducer,
  pet2000: petReducer2000,
  pet3000: petReducer3000,
  validation: validationReducer,
  auto: autoReducer,
  partial: partialReducer,
});

const persistedState = localStorage.getItem('formState')
  ? JSON.parse(localStorage.getItem('formState'))
  : {};
const {
  pet, pet2000, pet3000, auto, home,
} = persistedState;

const mergedPetState = merge({ ...initialPetState }, { ...pet });
const globalStoreObj = { ...rootReducer };
globalStoreObj.pet = mergedPetState;
globalStoreObj.pet2000 = pet2000;
globalStoreObj.pet3000 = pet3000;
globalStoreObj.auto = auto;
globalStoreObj.home = home;
if (screenWidth < mobileSize) {
  globalStoreObj.pet.activeStepperStep = 0;
}

const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}
const store = createStore(
  rootReducer,
  { ...globalStoreObj },
  applyMiddleware(...middlewares),
);

store.subscribe(() => {
  const {
    headerLogo,
    headerTitle,
    headerBtnList,
    breedsList,
    ...filteredStore
  } = store.getState().pet;
  const {
    pet2000: pet2000Store, pet3000: pet3000Store, auto: autoStore,
  } = store.getState();
  try {
    localStorage.setItem('formState', JSON.stringify({
      pet: filteredStore,
      pet2000: pet2000Store,
      pet3000: pet3000Store,
      auto: autoStore,
    }));
  } catch (e) {
    console.error(e);
  }
});

export default store;
