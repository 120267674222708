import { getTrims, getTrimsByYear } from '../Helpers/vehiclesHelper';
import { getZipFromLenox } from '../Requests/GetZipFromLenox';

export const AutoSiteId = '4c7879a2-da7b-4fe9-af69-8926cfc40072';

export const HomeSiteId = '34a00fd7-5cea-47e7-a49c-295056bf2b62';

export const LifeSiteID = '0f86aebb-8ad3-4ba0-90be-4ad5264f0157';

export const PetSiteID = 'c388128c-0e88-455b-8f49-7b31151785e0';

export const stateOptions = () => [
  { value: '-1', display: 'Select a State', title: 'Select a State' },
  { value: 'AL', display: 'Alabama', title: 'Alabama' },
  { value: 'AK', display: 'Alaska', title: 'Alaska' },
  { value: 'AZ', display: 'Arizona', title: 'Arizona' },
  { value: 'AR', display: 'Arkansas', title: 'Arkansas' },
  { value: 'CA', display: 'California', title: 'California' },
  { value: 'CO', display: 'Colorado', title: 'Colorado' },
  { value: 'CT', display: 'Connecticut', title: 'Connecticut' },
  { value: 'DE', display: 'Delaware', title: 'Delaware' },
  { value: 'DC', display: 'District of Columbia', title: 'District of Columbia' },
  { value: 'FL', display: 'Florida', title: 'Florida' },
  { value: 'GA', display: 'Georgia', title: 'Georgia' },
  { value: 'HI', display: 'Hawaii', title: 'Hawaii' },
  { value: 'ID', display: 'Idaho', title: 'Idaho' },
  { value: 'IL', display: 'Illinois', title: 'Illinois' },
  { value: 'IN', display: 'Indiana', title: 'Indiana' },
  { value: 'IA', display: 'Iowa', title: 'Iowa' },
  { value: 'KS', display: 'Kansas', title: 'Kansas' },
  { value: 'KY', display: 'Kentucky', title: 'Kentucky' },
  { value: 'LA', display: 'Louisiana', title: 'Louisiana' },
  { value: 'ME', display: 'Maine', title: 'Maine' },
  { value: 'MD', display: 'Maryland', title: 'Maryland' },
  { value: 'MA', display: 'Massachusetts', title: 'Massachusetts' },
  { value: 'MI', display: 'Michigan', title: 'Michigan' },
  { value: 'MN', display: 'Minnesota', title: 'Minnesota' },
  { value: 'MS', display: 'Mississippi', title: 'Mississippi' },
  { value: 'MO', display: 'Missouri', title: 'Missouri' },
  { value: 'MT', display: 'Montana', title: 'Montana' },
  { value: 'NE', display: 'Nebraska', title: 'Nebraska' },
  { value: 'NV', display: 'Nevada', title: 'Nevada' },
  { value: 'NH', display: 'New Hampshire', title: 'New Hampshire' },
  { value: 'NJ', display: 'New Jersey', title: 'New Jersey' },
  { value: 'NM', display: 'New Mexico', title: 'New Mexico' },
  { value: 'NY', display: 'New York', title: 'New York' },
  { value: 'NC', display: 'North Carolina', title: 'North Carolina' },
  { value: 'ND', display: 'North Dakota', title: 'North Dakota' },
  { value: 'OH', display: 'Ohio', title: 'Ohio' },
  { value: 'OK', display: 'Oklahoma', title: 'Oklahoma' },
  { value: 'OR', display: 'Oregon', title: 'Oregon' },
  { value: 'PA', display: 'Pennsylvania', title: 'Pennsylvania' },
  { value: 'RI', display: 'Rhode Island', title: 'Rhode Island' },
  { value: 'SC', display: 'South Carolina', title: 'South Carolina' },
  { value: 'SD', display: 'South Dakota', title: 'South Dakota' },
  { value: 'TN', display: 'Tennessee', title: 'Tennessee' },
  { value: 'TX', display: 'Texas', title: 'Texas' },
  { value: 'UT', display: 'Utah', title: 'Utah' },
  { value: 'VT', display: 'Vermont', title: 'Vermont' },
  { value: 'VA', display: 'Virginia', title: 'Virginia' },
  { value: 'WA', display: 'Washington', title: 'Washington' },
  { value: 'WV', display: 'West Virginia', title: 'West Virginia' },
  { value: 'WI', display: 'Wisconsin', title: 'Wisconsin' },
  { value: 'WY', display: 'Wyoming', title: 'Wyoming' },
];

export const homeOwnOptions = () => [
  { value: '-1', display: 'Please Select' },
  { value: '0', display: 'I\'m not a home owner' },
  { value: '6', display: '6 months' },
  { value: '12', display: '1 year' },
  { value: '24', display: '2 years' },
  { value: '36', display: '3 years' },
  { value: '48', display: '4 years' },
  { value: '60', display: '5 years' },
  { value: '72', display: '6 years' },
  { value: '84', display: '7 years' },
  { value: '96', display: '8 years' },
  { value: '108', display: '9 years' },
  { value: '120', display: '10+ years' },
];

export const validHomeOwnerYears = () => homeOwnOptions()
  .map((row) => row.value).filter((year) => Number.parseInt(year, 10) > 0);

export const propertyPersonalCoverage = () => [
  { value: '', display: 'Please Select' },
  { value: '5000', display: '$5,000' },
  { value: '10000', display: '$10,000' },
  { value: '15000', display: '$15,000' },
  { value: '20000', display: '$20,000' },
  { value: '25000', display: '$25,000' },
  { value: '30000', display: '$30,000' },
  { value: '35000', display: '$35,000' },
  { value: '40000', display: '$40,000' },
  { value: '45000', display: '$45,000' },
  { value: '50000', display: '$50,000' },
  { value: '55000', display: '$55,000' },
  { value: '60000', display: '$60,000' },
  { value: '65000', display: '$65,000' },
  { value: '70000', display: '$70,000' },
  { value: '75000', display: '$75,000' },
  { value: '80000', display: '$80,000' },
  { value: '85000', display: '$85,000' },
  { value: '90000', display: '$90,000' },
  { value: '95000', display: '$95,000' },
  { value: '100000', display: '$100,000' },
];

export const propertyPersonalCoverageRedux = [
  { value: '5000', title: '$5,000' },
  { value: '10000', title: '$10,000' },
  { value: '15000', title: '$15,000' },
  { value: '20000', title: '$20,000' },
  { value: '25000', title: '$25,000' },
  { value: '30000', title: '$30,000' },
  { value: '35000', title: '$35,000' },
  { value: '40000', title: '$40,000' },
  { value: '45000', title: '$45,000' },
  { value: '50000', title: '$50,000' },
  { value: '55000', title: '$55,000' },
  { value: '60000', title: '$60,000' },
  { value: '65000', title: '$65,000' },
  { value: '70000', title: '$70,000' },
  { value: '75000', title: '$75,000' },
  { value: '80000', title: '$80,000' },
  { value: '85000', title: '$85,000' },
  { value: '90000', title: '$90,000' },
  { value: '95000', title: '$95,000' },
  { value: '100000', title: '$100,000' },
];

export const dwellingOptions = () => [
  { value: '-1', display: 'Please Select' },
  { value: 'Single Family', display: 'Single Family' },
  { value: 'Duplex', display: 'Duplex' },
  { value: 'Mobile Home', display: 'Mobile Home' },
];

export const dwellingOptionsSpanish = () => [
  { value: '-1', display: 'Por Favor Seleccione' },
  { value: 'Single Family', display: 'Unifamiliar' },
  { value: 'Duplex', display: 'Dúplex' },
  { value: 'Mobile Home', display: 'Casa Móvil' },
];

export const trim = (props, byMake, value = false) => {
  const getTrimOptions = (makeFlag) => (makeFlag
    ? getTrims(
      props.formState.vehicleState[props.currentVehicle].make.value,
      value || props.formState.vehicleState[props.currentVehicle].model.value,
      props.vehiclesData,
    )
    : getTrimsByYear(
      props.formState.vehicleState[props.currentVehicle].year.value,
      value || props.formState.vehicleState[props.currentVehicle].model.value,
      props.vehiclesData,
    ));
  return (
    !!props.formState.vehicleState[props.currentVehicle].year.value
    && !!props.formState.vehicleState[props.currentVehicle].make.value
    && getTrimOptions(byMake)
  ) || [];
};

export const skipTrim = (props, byMake, value = false) => (trim(props, byMake, value)).length < 2;

export const skippingTrim = (props, byMake, modelValue = false) => {
  const trimOptions = trim(props, byMake, modelValue);
  const vehicleTrim = (trimOptions.length && trimOptions[0].value) || '';
  let vin = '';
  let formattedTrim = vehicleTrim;
  if (vehicleTrim.indexOf('_') >= 0 && vehicleTrim.split('_')[1]) {
    // eslint-disable-next-line prefer-destructuring
    vin = vehicleTrim.split('_')[1];
    [formattedTrim] = vehicleTrim.split('_');
  }
  props.handleSectionChange('vehicleState', props.currentVehicle, ['trim', 'vin'], [formattedTrim, vin], ['', '']);
};

export const returnYears = () => {
  const response = [{ value: '-1', display: 'Please Select' }];
  const today = new Date();
  let year = +(today.getFullYear());
  let i = 0;
  year = Number.parseInt(year, 10);
  while (i <= 105) {
    response.push({ value: year, display: year });
    year -= 1;
    i += 1;
  }
  return response;
};

export const buildParamsTrackStep = (props, siteID, step) => {
  let paramsBuilt = {
    step,
    site_id: siteID || 'missing_siteId',
    user_id: props.uuid,
    affid: props.affid && +props.affid !== 0 ? props.affid : localStorage.getItem('affidASR') || 0,
    form: `${(Number.parseInt(props.variantObject.form, 10) + (props.variantObject.form > 9 ? props.variantObject.variant : props.variantObject.variant - 1)) || localStorage.getItem('formID') || (props.variantObject.form === 'home' && 1)}`,
    ip: props.ip,
    user_agent: props.urlParams.user_agent || navigator.userAgent,
    referrer: localStorage.getItem('referrer') || props.urlParams.referrer,
    device: localStorage.getItem('device_track') || undefined,
    platform: localStorage.getItem('platform_track') || undefined,
    browser: localStorage.getItem('browser_track') || undefined,
    s1: props.urlParams.s1 || '',
    s2: props.urlParams.s2 || '',
    s3: props.urlParams.s3 || '',
    s4: props.urlParams.s4 || '',
    s5: props.urlParams.s5 || '',
    state: props.geoData ? props.geoData.state || '' : '',
    th: props.th || '',
    th_step: props.thStep || '',
    presell: (!!props.presellData && props.presellData.presell) || '',
    landing: (step === 'landing' && '16') || '0',
  };
  if (props.urlParams.max_cid) {
    paramsBuilt = { ...paramsBuilt, max_cid: props.urlParams.max_cid };
  }
  return paramsBuilt;
};

export const skipSections = async (pages, state, setState) => {
  const newSkippedPages = [...state.skippedPages];
  pages.forEach((page) => !(newSkippedPages.indexOf(page) >= 0) && newSkippedPages.push(page));
  setState('skippedPages', newSkippedPages);
};

export const removePagesFromSkipped = async (pages, state, setState) => {
  const newSkippedPages = [...state.skippedPages];
  pages.forEach((page) => {
    const index = newSkippedPages.indexOf(page);
    if (index >= 0) {
      newSkippedPages.splice(index, 1);
    }
  });
  setState('skippedPages', newSkippedPages);
};

export const getNextPageIndex = (pages, pageModifier, currentPage, skippedPages) => {
  let nextPageIndex = pages.indexOf(currentPage) + pageModifier;
  while (skippedPages.indexOf(pages[nextPageIndex]) >= 0) {
    nextPageIndex += pageModifier;
  }
  return nextPageIndex;
};

export const getCityStateFromRapidApi = async (zip) => {
  if (zip && zip.length === 5) {
    try {
      const rapidApiData = await getZipFromLenox(zip);
      if (rapidApiData && rapidApiData.data) {
        return rapidApiData.data;
      }
      return rapidApiData.data;
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const isStateAllowedForTransunion = (geoData) => {
  const badStates = ['AZ', 'CA', 'HI', 'NH', 'OK', 'PA', 'VA', 'NY'];
  if (!geoData) {
    return false;
  }
  return !!geoData.state && !(badStates.indexOf(geoData.state) >= 0);
};

export const showLookupBtnFunction = (props, page) => {
  if (props.geoData) {
    return Object.keys(props.variant.pages[page].fields).indexOf('transunion_banner') >= 0 && isStateAllowedForTransunion(props.geoData);
  }
  return false;
};

const buildQueryString = (params) => Object.entries(params)
  .filter(([key]) => ['affid', 'form', 'variant_number', 's1', 's2', 's3', 's4', 's5'].indexOf(key) >= 0)
  .reduce((currentQuery, [key, value], idx) => (idx === 0 ? `${currentQuery}${key}=${value}` : `${currentQuery}&${key}=${value}`), '?');

export const nonMAaffiliates = [
  '2021',
];

export const backButton = (history, props, event = false, path = '/lb') => {
  if (nonMAaffiliates.indexOf(props.affid.toString()) === -1) {
    if (event) {
      event.preventDefault();
      // eslint-disable-next-line no-param-reassign
      event.returnValue = '';
    }
    const queryString = buildQueryString(props.urlParams);
    history({
      pathname: path,
      search: `${queryString}`,
    });
    return true;
  }
  return '';
};

export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  if (c > 0) {
    window.requestAnimationFrame(scrollToTop);
    window.scrollTo(0, c - c / 8);
  }
};
