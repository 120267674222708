import { NetworkRequest } from '../Helpers/NetworkRequest';

export const getZipFromLenox = async (zip) => {
  let response;
  if (localStorage.getItem(`${zip}zipFromLenox`)) {
    response = JSON.parse(localStorage.getItem(`${zip}zipFromLenox`));
  } else {
    response = await NetworkRequest('rapidApi', {
      zipcode: zip,
      affid: localStorage.getItem('affidASR'),
      uuid: localStorage.getItem('uuidASR'),
    });
    localStorage.setItem(`${zip}zipFromLenox`, JSON.stringify(response));
  }
  return response;
};
