import { UPDATE_PARTIAL_STATE } from './partial.types';

const initialState = {};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case UPDATE_PARTIAL_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
