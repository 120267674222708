import { CLEAR_VALIDATION_STATE, UPDATE_VALIDATION_STATE } from './type.validation';

const initialState = {

};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case UPDATE_VALIDATION_STATE: {
      return { ...state, ...action.payload };
    }
    case CLEAR_VALIDATION_STATE: {
      return { ...initialState };
    }
    default: return state;
  }
};
