import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Phone } from '@material-ui/icons';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import ImageFallback from '../../Common/CommonComponents/ImageFallback';
import './style/otto.css';
import companyAddress from '../../Common/companyAddress';

const autoLink = 'https://ottoinsurance.com/?form=2001&affid=669';
const phoneLink = '1-888-804-5641';

export function FacebookPixel(fbID) {
  const fbPixel = `!(function (f, b, e, v, n, t, s) {
    if (f.fbq) return; n = f.fbq = function () {
      n.callMethod
        ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
    };
    if (!f._fbq)f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
    n.queue = []; t = b.createElement(e); t.async = !0;
    t.src = v; s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  }(window, document, 'script',
    'https://connect.facebook.net/en_US/fbevents.js'));
  fbq('init', '${fbID}');
  fbq('track', 'PageView')`;

  const fbPixelImg = document.createElement('img');
  fbPixelImg.src = `https://www.facebook.com/tr?id=${fbID}&ev=PageView&noscript=1`;
  fbPixelImg.height = '1';
  fbPixelImg.width = '1';
  fbPixelImg.style = 'display:none';

  const FBScript = document.createElement('script');
  FBScript.type = 'text/javascript';
  FBScript.async = true;
  FBScript.innerHTML = fbPixel;

  const FBScriptNoScript = document.createElement('noscript');
  FBScriptNoScript.type = 'text/javascript';
  FBScriptNoScript.async = true;
  FBScriptNoScript.appendChild(fbPixelImg);
}

export function FireFBPixel(fbID) {
  return document.getElementsByTagName('head')[0].append(FacebookPixel(fbID));
}

export function GooglePixel1(googleID) {
  const pixel1 = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googleID}');
        `;

  const pixelScript1 = document.createElement('script');
  pixelScript1.type = 'text/javascript';
  pixelScript1.async = false;
  pixelScript1.innerHTML = pixel1;
  return pixelScript1;
}

export function GooglePixel2(googleID) {
  const pixel2 = `
    gtag('event', 'conversion', {'send_to': '${googleID}/SuwCCJyX55YBENrdsfIC'});
  `;

  const pixelScript2 = document.createElement('script');
  pixelScript2.type = 'text/javascript';
  pixelScript2.async = false;
  pixelScript2.innerHTML = pixel2;
  return pixelScript2;
}

export function GoogleScript(googleID) {
  const FBScript = document.createElement('script');
  FBScript.type = 'text/javascript';
  FBScript.async = true;
  FBScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleID}`;
  return FBScript;
}

export function FireGooglePixel(googleID) {
  document.getElementsByTagName('head')[0].append(GoogleScript(googleID));
  document.getElementsByTagName('head')[0].append(GooglePixel1(googleID));
  return document.getElementsByTagName('head')[0].append(GooglePixel2(googleID));
}

export function ClickyScript() {
  const ClickScript = document.createElement('script');
  ClickScript.type = 'text/javascript';
  ClickScript.async = true;
  ClickScript.setAttribute('data-id', '101453242');
  ClickScript.src = '//static.getclicky.com/js';
  return document.getElementsByTagName('head')[0].append(ClickScript);
}

const RenderSection = function ({ children }) {
  return (
    <Grid item xs={12}>
      {children}
    </Grid>
  );
};

RenderSection.propTypes = {
  children: PropTypes.node.isRequired,
};

const renderLogo = () => (
  <div className="otto2-logo-container">
    <ImageFallback
      className="otto2-logo"
      alt="Otto Insurance"
      fallback={`${process.env.PUBLIC_URL}/assets/form-imgs/Otto_BlueLogo.png`}
      src={`${process.env.PUBLIC_URL}/assets/form-imgs/Otto_BlueLogo.webp`}
    />
  </div>
);

const renderSocial = () => (
  <ul className="otto-footer-social">
    <li>
      <a href="https://www.facebook.com/Ott0Insurance" target="_blank" rel="noopener noreferrer">
        <FacebookIcon className="otto-footer-social-icon" />
      </a>
    </li>
    <li>
      <a href="https://twitter.com/OttoInsurance" target="_blank" rel="noopener noreferrer">
        <TwitterIcon className="otto-footer-social-icon" />
      </a>
    </li>
    <li>
      <a href="https://www.youtube.com/channel/UCLpu8tUqooCqvwyC2FgQraQ" target="_blank" rel="noopener noreferrer">
        <YouTubeIcon className="otto-footer-social-icon" />
      </a>
    </li>
  </ul>
);
const renderFooter = () => (
  <div className="otto2-footer">
    <Grid container alignItems="center">
      <Grid item xs={12} md={8}>
        <div className="otto2-inline-links">
          <a className="otto2-email-link" href="mailto:info@ottoinsurance.com">info@ottoinsurance.com</a>
          <div className="otto2-inline-links-container">
            <span className="hide-on-mobile">&nbsp; | &nbsp;</span>
            <a className="link-text" href="/news" target="_blank" rel="noopener noreferrer">Insurance News</a>
            &nbsp; | &nbsp;
            <a className="link-text" href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
            &nbsp; | &nbsp;
            <a className="link-text" href="/privacy#CAPrivacyRights" target="_blank" rel="noopener noreferrer">CA Privacy Rights</a>
            &nbsp; | &nbsp;
            <a className="link-text" href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
            &nbsp;
          </div>
        </div>
        <p>
          Copyright &copy; 2021 OttoInsurance.com &nbsp; | &nbsp;
          {companyAddress}
        </p>
      </Grid>
      <Grid item xs={12} md={4} className="otto-social-container">
        {renderSocial()}
      </Grid>
    </Grid>
  </div>
);

const renderHeader = () => (
  <Grid container justify="center" alignItems="center">
    <Grid item xs={6}>
      {renderLogo()}
    </Grid>
    <Grid item xs={6} className="otto2-header-links">
      <a className="otto2-header-link hide-on-mobile" href={autoLink}>
        Auto Insurance
      </a>
      <a className="otto2-phone-link" href={`tel:${phoneLink}`}>
        <Phone className="otto2-phone-link-icon" />
        {phoneLink}
      </a>
    </Grid>
  </Grid>
);

const renderTopMascotCTA = () => (
  <Grid container justify="center" alignItems="center">
    <Grid className="otto2-main-title" item xs={10}>
      <div>
        <ImageFallback
          className="otto2-mascot"
          alt="Otto Insurance"
          fallback={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Insurance_Paint.jpg`}
          src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Insurance_Paint.webp`}
        />
      </div>
    </Grid>
    <Grid className="otto2-insurance-icons" item xs={12} sm={8}>
      <ImageFallback
        className="otto2-mascot"
        alt="Otto Insurance"
        fallback={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Glasses_Paint_Car_Cropped.jpg`}
        src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Glasses_Paint_Car_Cropped.webp`}
      />
    </Grid>
  </Grid>
);

const renderLearnMore = () => (
  <Grid className="otto2-learn-more-container" container justify="center" alignItems="center">
    <Grid item xs={12} sm={8}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <h2 id="lets-learn" className="otto2-learn-more-heading">
            Let&apos;s learn a little about you.
          </h2>
          <p className="otto2-learn-more-content">
            Help us learn more about you and your car
            so that we can match you to the perfect policy. Don&apos;t
            worry, it only takes about two minutes to get your quote.
          </p>
        </Grid>
      </Grid>
    </Grid>
    <Grid className="otto2-phone-form-container" item xs={12} sm={4}>
      <ImageFallback
        className="otto2-form-image-1"
        alt="Otto Insurance Form"
        fallback={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Form_Combined.png`}
        src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Form_Combined.webp`}
      />
    </Grid>
  </Grid>
);

const renderCompareSave = () => (
  <Grid className="otto2-compare-save-container" container justify="center" alignItems="center">
    <Grid className="otto2-compare-image-container" item xs={12} sm={5}>
      <ImageFallback
        className="otto2-otto-phone-savings"
        alt="Otto Insurance Form"
        fallback={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Phone.jpg`}
        src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Phone.webp`}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={12}>
          <h2 id="compare-and-save" className="otto2-compare-save-heading">
            Compare and start saving.
          </h2>
          <p className="otto2-compare-save-content">
            We&apos;ll work our magic and deliver quotes
            in seconds based on your information. Easily compare
            them in one place and decide which plan best fits your needs.
            <br />
            <br />
            When drivers visit unbiased sites like OTTO Insurance, they can view all the best rates in their area. Survey
            data indicates it is possible to save $500+ per year on their car insurance rates.
          </p>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const renderWhyChooseAuto = () => (
  <Grid className="otto2-why-choose-container" container justify="center" alignItems="center">
    <Grid item xs={12}>
      <h2 className="otto2-choose-main-title">
        Why choose Otto?
      </h2>
    </Grid>
    <Grid item xs={12} sm={8}>
      <Grid container justify="center" alignItems="flex-start">
        <Grid item xs={12} sm={4}>
          <div className="why-choose-container">
            <div className="choose-icon-circle">
              <img
                className="otto2-choose-circle"
                src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/certificate.svg`}
                alt="Otto Form"
              />
            </div>
            <div className="choose-content-container">
              <h4 className="choose-title">EXPERIENCED</h4>
              <ul className="choose-content">
                <li>Over 1,000,000 insurance customers helped.</li>
                <li>We connect with hundreds of carriers to find the right match.</li>
                <li>Driven by data and backed by decades of experience.</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="why-choose-container">
            <div className="choose-icon-circle choose-icon-circle-mobile-margin">
              <img
                className="otto2-choose-circle"
                src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/clock.svg`}
                alt="Otto Form"
              />
            </div>
            <div className="choose-content-container">
              <h4 className="choose-title">EFFICIENT</h4>
              <ul className="choose-content">
                <li>Complete our form in the time it takes to brew a cup of coffee.</li>
                <li>Get matched to an agent in seconds.</li>
                <li>Our team is ready to help anytime, anywhere.</li>
              </ul>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="why-choose-container">
            <div className="choose-icon-circle choose-icon-circle-mobile-margin">
              <img
                className="otto2-choose-circle"
                src={`${process.env.PUBLIC_URL}/assets/LP/Otto2/lock.svg`}
                alt="Otto Form"
              />
            </div>
            <div className="choose-content-container">
              <h4 className="choose-title">SECURE</h4>
              <ul className="choose-content">
                {/* <li>We’re a BBB Accredited Business.</li> */}
                <li>Your data is protected with McAfee Secure.</li>
                <li>We use cutting-edge technology to get you quotes quickly and safely.</li>
              </ul>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const renderLetsSee = () => (
  <Grid className="otto2-lets-see-container" container justify="center" alignItems="center">
    <Grid item xs={12}>
      <h2 className="otto2-lets-see-title">
        Let&apos;s see how much you can save!
      </h2>
    </Grid>
    <Grid item xs={12} sm={8}>
      <Grid container justify="center" alignItems="center">
        <Grid className="otto2-lets-see-link-container" item xs={12} sm={4}>
          <a className="otto2-lets-see-button-link" href={autoLink}> GET STARTED NOW </a>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const renderSteps = () => (
  <Grid className="otto2-steps-container" container justify="center" alignItems="center">
    <Grid item xs={12}>
      <Grid container justify="center" alignItems="center">
        <Grid className="otto2-step" item xs={12} sm={3}>
          <div className="otto2-steps-number">1</div>
          <h3 className="otto2-steps-text">
            Tell us about
            &nbsp;
            <span className="bold-italic">yourself.</span>
          </h3>
        </Grid>
        <Grid className="otto2-step" item xs={12} sm={4}>
          <div className="otto2-steps-number">2</div>
          <h3 className="otto2-steps-text">
            Get matched with
            &nbsp;
            <span className="bold-italic">personalized policies.</span>
          </h3>
        </Grid>
        <Grid className="otto2-step" item xs={12} sm={5}>
          <div className="otto2-steps-number">3</div>
          <h3 className="otto2-steps-text">
            Choose the best plan that
            &nbsp;
            <span className="bold-italic">suits your needs.</span>
          </h3>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

const renderNews = () => (
  <Grid className="otto2-lets-see-container" container justify="center" alignItems="center">
    <Grid className="otto2-lets-see-title" item xs={12}>
      News Stories
    </Grid>
    <Grid item xs={10} sm={8} className="news-story-container">
      <a
        href="/news/Should-You-Pay-For-Repairs-Out-of-Pocket.html"
        className="news-link-headline"
      >
        Should you pay for repairs out of pocket?
      </a>
      <p>
        When you’ve been in an accident, your first instinct is probably to call your insurance company
        to begin filing a claim. However, is it always worth the effort it takes to file a claim? There
        are some cases in which it may be less hassle to pay expenses out of pocket. Here’s how to tell
        the difference.
      </p>
      <a
        href="/news/Should-You-Pay-For-Repairs-Out-of-Pocket.html"
        className="news-read-more"
      >
        Read More
      </a>
    </Grid>

    <Grid item xs={10} sm={8} className="news-story-container">
      <a
        href="/news/How-to-Prevent-Your-Car-Paint-From-Becoming-Oxidized.html"
        className="news-link-headline"
      >
        How to Prevent Your Car Paint From Becoming Oxidized
      </a>
      <p>
        Oxidized paint isn’t the most attractive look for your car, and it turns out that it
        isn’t the best for your wallet, either. Beyond the obvious cost it would take to repair the
        paint, you could also lose money when you go to sell it, too.
      </p>
      <a
        href="/news/How-to-Prevent-Your-Car-Paint-From-Becoming-Oxidized.html"
        className="news-read-more"
      >
        Read More
      </a>
    </Grid>

    <Grid item xs={10} sm={8} className="news-story-container">
      <a
        href="/news/How-to-Transport-a-Classic-Car.html"
        className="news-link-headline"
      >
        How to Transport a Classic Car in 5 Steps
      </a>
      <p>
        In the best of times, a long trip can be daunting and exhausting for any driver,
        no matter how much you love driving. Long trips can be even tougher when you’re
        also trying to get your classic car from Point A to Point B. And if you’re not
        just taking it for a pleasure drive, the logistics can be even tougher. If you’re
        moving two states over, you may not be able to drive your car that distance!
      </p>
      <a
        href="/news/How-to-Transport-a-Classic-Car.html"
        className="news-read-more"
      >
        Read More
      </a>
    </Grid>

    <Grid item xs={10} sm={8} className="news-story-container">
      <a
        href="/news/Do-electric-cars-cost-more-to-insure.html"
        className="news-link-headline"
      >
        Do electric cars cost more to insure?
      </a>
      <p>
        Though many major car manufacturers produce at least one type of electric car, they haven’t
        quite caught up to traditional vehicle sales. Though that milestone is probably years down
        the road, that isn’t to say electric cars don’t sell. Over the last decade, electric car sales
        have skyrocketed, thanks in part to the advent of Tesla models.
      </p>
      <a
        href="/news/Do-electric-cars-cost-more-to-insure.html"
        className="news-read-more"
      >
        Read More
      </a>
    </Grid>

    <Grid item xs={10} sm={8} className="news-story-container">
      <a
        href="/news/Conventional_vs_Synthetic_Oil_Which_is_Better.html"
        className="news-link-headline"
      >
        Conventional vs Synthetic Oil: Which is Better?
      </a>
      <p>
        Even if you only know the basics about car maintenance, you probably still know that part of
        maintaining your car means getting regular oil changes. However, you may not know about the
        different types of oil available—or why it matters what your mechanic is putting in your car.
      </p>
      <a
        href="/news/Conventional_vs_Synthetic_Oil_Which_is_Better.html"
        className="news-read-more"
      >
        Read More
      </a>
    </Grid>
  </Grid>
);

const renderImportant = () => (
  <div className="otto2-important-disclaimer">
    Even if you already have insurance, you can almost always cancel your current policy with no fee and be reimbursed
    in order to purchase your newly found better policy.
  </div>
);

const Otto = function () {
  useEffect(() => {
    FireFBPixel('201047007923936');
    FireGooglePixel('AW-776761050');
    ClickyScript();
    // eslint-disable-next-line
  }, []);
  document.getElementById('favicon').setAttribute('href', `${process.env.PUBLIC_URL}/assets/LP/Otto2/Otto_Favicon.png`);

  return (
    <div className="otto2-background">
      <div className="otto2-container">
        <Grid container justify="center" alignItems="flex-start">
          <RenderSection>
            {renderHeader()}
          </RenderSection>
          <RenderSection>
            {renderTopMascotCTA()}
          </RenderSection>
          <RenderSection>
            {renderLearnMore()}
          </RenderSection>
          <RenderSection>
            {renderCompareSave()}
          </RenderSection>
          <RenderSection>
            {renderWhyChooseAuto()}
          </RenderSection>
          <RenderSection>
            {renderLetsSee()}
          </RenderSection>
          <RenderSection>
            {renderSteps()}
          </RenderSection>
          <RenderSection>
            {renderNews()}
          </RenderSection>
          <RenderSection>
            {renderImportant()}
          </RenderSection>
          <RenderSection>
            {renderFooter()}
          </RenderSection>
        </Grid>
      </div>
    </div>
  );
};

export default Otto;
