import TOGGLE_LOADER from './types.loader';

const initialState = {
  isShown: false,
};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case TOGGLE_LOADER: {
      return { ...state, isShown: !state.isShown };
    }
    default:
      return state;
  }
};
