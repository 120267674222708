import {
  ADD_PAGE_TO_SKIP_LIST,
  REMOVE_PAGE_FROM_SKIP_LIST,
  UPDATE_AUTO_STATE,
  UPDATE_DEEP_AUTO_STATE,
  DELETE_CERTAIN_AUTO_DATA,
  RENAME_CERTAIN_AUTO_DATA,
} from './Auto.type';

const initialState = {
  currentPage: 0,
  vehicleCount: 1,
  driverCount: 1,
  currentVehicle: 1,
  currentDriver: 1,
  skipPages: ['additionalDriverRelationshipPage'],
  vehiclesData: {},
  vehicleList: [],
  vehicleModelList: [],
  vehicleState1: {},
  driverState1: {},
  leaveBehindState: {},
  formTransitionClass: '',
  coreg: true,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_AUTO_STATE: {
      return { ...state, ...action.payload };
    }
    case ADD_PAGE_TO_SKIP_LIST: {
      return { ...state, skipPages: [...state.skipPages, action.payload] };
    }
    case REMOVE_PAGE_FROM_SKIP_LIST: {
      return { ...state, skipPages: state.skipPages.filter((page) => page !== action.payload) };
    }
    case UPDATE_DEEP_AUTO_STATE: {
      if (action.payload.currentPageData.fields) {
        const keys = Object.keys(action.payload).find((key) => Object.keys(action.payload.currentPageData.fields).some((inputName) => action.payload.currentPageData.fields[inputName].key === key));
        return { ...state, [action.payload.getDeepPageKey]: { ...state[action.payload.getDeepPageKey], [keys]: action.payload[keys] } };
      }
      return { ...state, [action.payload.getDeepPageKey]: { ...state[action.payload.getDeepPageKey], [action.payload.currentPageData.pageKey]: action.payload[action.payload.currentPageData.pageKey] } };
    }
    case DELETE_CERTAIN_AUTO_DATA: {
      const next = { ...state };
      delete next[action.payload.storeKey];
      return next;
    }
    case RENAME_CERTAIN_AUTO_DATA: {
      const { newKey, oldKey } = action.payload;
      delete Object.assign(state, { [newKey]: state[oldKey] })[oldKey];
      return { ...state };
    }
    default: {
      return state;
    }
  }
};
