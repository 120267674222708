const DEV_SERVER = process.env.REACT_APP_LENOX_DEV_SERVER || '';
let baseURL = () => 'https://api.uselenox.com';

if (DEV_SERVER) {
  baseURL = () => DEV_SERVER;
}

// eslint-disable-next-line import/prefer-default-export
export const getDataUrl = () => ({
  auto: `${baseURL()}/api/v2/external/auto.php`,
  pet: `${baseURL()}/api/v2/external/pet.php`,
  sites: `${baseURL()}/api/v2/external/sites.php`,
  emailValidation: `${baseURL()}/api/v2/external/validateEmail.php`,
  phoneValidation: `${baseURL()}/api/v2/external/validatePhoneNumber.php`,
  nameValidation: `${baseURL()}/api/v2/external/validateNames.php`,
  trackStep: `${baseURL()}/api/v2/external/ping.php`,
  getPixels: `${baseURL()}/api/v2/external/pixels.php`,
  animatedTY: `${baseURL()}/api/v2/external/animated_ty.php`,
  logPixels: `${baseURL()}/api/v2/external/pixel_log.php`,
  contactability: `${baseURL()}/api/v2/external/contactability.php`,
  clearcover: `${baseURL()}/api/v2/external/clearcover.php`,
  ty_log: `${baseURL()}/api/v2/external/ty_log.php`,
  ty: `${baseURL()}/api/v2/external/ty.php`,
  clicklisting: `${baseURL()}/api/v2/external/getClicklisting.php`,
  variants: `${baseURL()}/api/v2/external/variants.php`,
  testAsset: `${baseURL()}/api/v2/external/testAsset.php`,
  getPayout: `${baseURL()}/api/v3/external/getPayout.php`,
  spendData: `${baseURL()}/api/v2/external/spendData.php`,
  bandits: `${baseURL()}/api/v3/external/bandits.php`,
  trackPresellStep: `${baseURL()}/api/v2/external/presell_step_log.php`,
  privacyRequest: `${baseURL()}/api/v2/external/privacyRequest.php`,
  sendError: `${baseURL()}/api/v2/external/error.php`,
  vehicles: `${baseURL()}/api/v3/external/vehicles.php`,
  validateAddress: `${baseURL()}/api/v3/external/validateAddress.php`,
  tyText: `${baseURL()}/api/v2/external/ty.php`,
  peopleFinder: `${baseURL()}/api/v3/external/pf.php`,
  rapidApi: `${baseURL()}/api/v3/external/citystate.php`,
  errorPing: `${baseURL()}/api/v2/external/error_ping.php`,
  generateUUID: `${baseURL()}/api/v2/external/generate_uuid.php`,
  complete_prefill: `${baseURL()}/api/v2/external/complete_prefill.php`,
  ttclid: `${baseURL()}/api/v2/external/ttclid.php`,
  fbclid: `${baseURL()}/api/v2/external/fbclid.php`,
  click_payload: `${baseURL()}/api/v3/external/click_payload.php`,
  links_payload: `${baseURL()}/api/v3/external/links_payload.php`,
});
