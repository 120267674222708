import React from 'react';

const renderLogo = (props) => (
  <div className="logo-container">
    {/* <img className={'logo'} src={`https://dq3ll41r32qbq.cloudfront.net/5e39bda89ca3e.jpg`} alt="Auto Logo"/> */}
    <img className="logo" src={`https://dq3ll41r32qbq.cloudfront.net${props.logo}`} alt="Auto Logo" />
  </div>
);

const renderTitle = () => (
  <div className="page-title">
    Rise Above The Jungle of Auto Insurance
  </div>
);

const renderThankYou = () => (
  <div className="page-title ty">
    Thank you for subscribing!
  </div>
);

const renderCTAContainer = (handleChange, email, submit, submitted) => (
  <div style={{ display: submitted ? 'none' : 'inherit' }} className="cta-container">
    <div id="mc_embed_signup">
      <form
        action="https://thegiraffe.us4.list-manage.com/subscribe/post?u=79a328d9b0b00ebe232206fe2&amp;id=0a87bfef15"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        onSubmit={submit}
        target="_blank"
      >
        <div id="mc_embed_signup_scroll">
          <input
            type="email"
            value={email}
            name="EMAIL"
            className="email cta-input"
            id="mce-EMAIL"
            placeholder="Enter email address"
            onChange={({ target: { value } }) => handleChange(value)}
            required
          />
          <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
            <input
              type="text"
              name="b_79a328d9b0b00ebe232206fe2_0a87bfef15"
              tabIndex="-1"
              value=""
            />
          </div>
          <button
            type="submit"
            name="subscribe"
            id="mc-embedded-subscribe"
            className="cta-button"
          >
            Get Early Access
          </button>
        </div>
      </form>
    </div>
  </div>
);

const renderFooter = () => (
  <div className="footer">
    <p>Copyright©2020 TheGiraffe.com</p>
    <div className="inline-links">
      <p><strong>info@thegiraffe.com</strong></p>
      &nbsp; | &nbsp;
      <a className="link-text" href="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
      &nbsp; | &nbsp;
      <a className="link-text" href="privacy#CAPrivacyRights" target="_blank" rel="noopener noreferrer">CA Privacy Rights</a>
      &nbsp; | &nbsp;
      <a className="link-text" href="/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a>
      &nbsp;
    </div>
  </div>
);

const TheGiraffe = function (props) {
  require('./style/giraffe.css');
  const [email, setEmail] = React.useState('');
  const [submitted, setSubmitted] = React.useState(false);

  const handleChange = () => setEmail(email);

  const submit = () => setSubmitted(true);

  return (
    <div className="background">
      <div className="container">
        {renderLogo(props)}
        {renderTitle()}
        {renderCTAContainer(handleChange, email, submit, submitted)}
        {submitted && renderThankYou()}
        {renderFooter()}
      </div>
    </div>
  );
};

export default TheGiraffe;
