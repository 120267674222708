import * as PET_TYPES from './petTypes';
import { initialPetState } from './Constants';

export default (state = { ...initialPetState }, action = {}) => {
  switch (action.type) {
    case PET_TYPES.UPDATE_PET_TYPE: {
      return { ...state, ...action.payload };
    }
    case PET_TYPES.RESET_STATE: {
      return { ...initialPetState, petsList: [] };
    }
    case PET_TYPES.ERROR_CLEAN: {
      return { ...state, errorsDescription: '' };
    }
    default:
      return state;
  }
};
