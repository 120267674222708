import UPDATE_MAIN_STATE from './main.types';

const initialState = {
  variantObject: false,
  form: false,
  variant: false,
  geoData: false,
  initialDataToPayload: {},
  tyType: '',
  lbType: '',
};

export default (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case UPDATE_MAIN_STATE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
