const activeLandingPages = ['abonoor', 'thegiraffe', 'localhost', 'ottoinsurance'];

const extractDomainFromHostname = () => {
  const { hostname } = window.location;
  const hostArray = hostname.split('.');
  if (hostArray && hostArray.length > 0 && hostArray.length === 3) {
    return hostArray[1];
  }
  if (hostArray && hostArray.length > 0 && hostArray.length <= 2) {
    return hostArray[0];
  }
  return '';
};

export default async () => {
  const lowercaseDomain = extractDomainFromHostname().toLowerCase();
  if (lowercaseDomain && activeLandingPages.indexOf(lowercaseDomain) >= 0) {
    try {
      // eslint-disable-next-line
      const Domain = await require(`/${lowercaseDomain}.jsx`).default;
      if (Domain) {
        return `${lowercaseDomain}.jsx`;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  }
  return false;
};
