import UPDATE_STORE from './petType.3000';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_STORE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
