import React, { lazy, Suspense } from 'react';
import { Provider } from 'react-redux';
import landingPageGetter from './AutoSavings/LandingPages/LandingPageGetter';
import SuspensePageLoader from './Utils/SuspensePageLoader';
import store from './redux';

const App = lazy(() => import(/* webpackChunkName: "App" */ './App'));

class LPRedirector extends React.Component {
  // eslint-disable-next-line react/state-in-constructor
  state = { loadingLP: true, LpRedirect: false };

  componentDidMount() {
    this.loadLandingPage();
  }

  loadLandingPage = async () => {
    const landingPagePath = await landingPageGetter();
    if (landingPagePath && (
      window.location.href === 'https://abonoor.com/'
      || window.location.href === 'http://localhost:3000/'
      || window.location.href === 'http://abonoor.com/'
      || window.location.href === 'https://ottoinsurance.com/'
      || window.location.href === 'https://www.ottoinsurance.com/'
      || window.location.href === 'http://ottoinsurance.com/'
      || window.location.href === 'http://www.ottoinsurance.com/'
    )) {
      // eslint-disable-next-line import/no-dynamic-require
      const LandingPage = await require(`./AutoSavings/LandingPages/${landingPagePath}`).default;
      this.setState({ LpRedirect: LandingPage || false, loadingLP: false });
    } else {
      this.setState({ LpRedirect: false, loadingLP: false });
    }
  };

  render() {
    const { loadingLP, LpRedirect } = this.state;

    if (loadingLP) {
      return <SuspensePageLoader />;
    }

    if (LpRedirect) {
      return (<LpRedirect {...this.props} goToForm={this.goToForm} />);
    }

    return (
      <Suspense fallback={<SuspensePageLoader />}>
        <Provider store={store}>
          <App />
        </Provider>
      </Suspense>
    );
  }
}

export default LPRedirector;
